import { defaultDirection } from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}


export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const downloadFunctions = {
  download (base64, name) {
    const blob = this.base64ToBlob(base64);
    return navigator.msSaveBlob
        ? navigator.msSaveBlob(blob, name)
        : this.htmlDownload(blob, name);
  },

  base64ToBlob (data) {
      const dec = atob(data);
      const len = dec.length;
      const array = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
          array[i] = dec.charCodeAt(i);
      }
      return new window.Blob([array], { type: 'application/octet-stream' });
  },

  htmlDownload (blob, name) {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = name;
      document.body.appendChild(a);

      setTimeout(function () {
          a.click();
          document.body.removeChild(a);
      }, 200);
  }
}